@import url("https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap");

.dashboard-recruitment {
  background-color: rgb(238, 242, 246);
  width: 97%;
  min-height: calc(150vh - 150px);
  flex-grow: 1;
  padding: 20px;
  max-width: 100%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: 0;
  border-radius: 12px 12px 0px 0px;
  transition: width 0.3s ease-in-out;
}

.dashboard-recruitment.half-width {
  width: 85%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.recruitment-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 5rem;
  left: 17rem;
}

.recruitment-tile {
  font-size: 20px;
  font-family: Satoshi;
  font-weight: 700;
  color: #451397;
  text-align: center;
}

.sources-container {
  background: #6c80dc;
  border: 1px solid rgba(76, 0, 229, 0.38);
  border-radius: 20px;
  height: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  position: absolute;
  color: #fff;
  top: 88px;
  width: 25rem;
  left: 2rem;
  box-shadow: rgba(14, 30, 37, 0.12) -10px 7px 14px 0px,
    rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
}

/* Shape 1 */
.sources-container .shape1 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 120px;
  height: 120px;
  background: rgba(0, 32, 255, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.1px);
  -webkit-backdrop-filter: blur(4.1px);
  border-radius: 50%;
  border-radius: 10px;
}

/* Shape 2 */
.sources-container .shape2 {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 120px;
  height: 120px;
  background: rgba(0, 32, 255, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.1px);
  -webkit-backdrop-filter: blur(4.1px);
  border-radius: 50%;
  border-radius: 10px;
}

h1.title-sources {
  font-size: 15px;
  font-family: Satoshi;
  font-weight: 700;
  color: #451397;
  text-align: center;
}

.dropdown-container {
  display: flex;
  justify-content: center;
}

.box-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  flex-direction: column;
  font-family: Satoshi;
  font-size: 15px;
  font-weight: 600;
  line-height: 0.5;
}

.label-progress {
  font-size: 15px;
  font-family: Satoshi;
  font-weight: 700;
  color: #451397;
  padding-top: 20px;
  padding-left: 22rem;
}

p.label-box-progress {
  font-size: 15px;
  font-family: Satoshi;
  font-weight: 500;
  line-height: 0.5;
}

.interviews-box-container {
  position: relative;
  top: 5.4rem;
  left: -7rem;
  width: 30rem;
}

.tile {
  background-color: #3e3298;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  width: 195px;
  font-family: Satoshi;
  font-size: 15px;
  font-weight: 600;
  color: aliceblue;
  transition: transform 0.3s ease-in-out;
}

.tile:hover {
  transform: translateY(-5px);
  cursor: pointer;
  color: #ffffff;
  /* Change the color when hovered */
}

.tile i {
  font-size: 36px;
  margin-bottom: 10px;
  transition: color 0.3s ease-in-out;
  /* Add transition for the color change */
}

.tile:hover i {
  color: #d9fa21;
  /* Change the color of the icon when the tile is hovered */
}

.tile h3 {
  font-size: 18px;
  margin-bottom: 8px;
  padding: 0;
  margin-top: 0;
}

.tile .number {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.recruitment-tile-2 {
  position: relative;
  bottom: 120px;
}

.interviews-box {
  border: 1px solid #0a0a0a29;
  border-radius: 15px;
  padding: 10px;
  background-color: #451397;
  color: #007bff;
  font-family: Satoshi;
  font-size: 15px;
  font-weight: 600;
  line-height: 0.5;
  box-shadow: rgba(14, 30, 37, 0.12) 10px 7px 14px 0px,
    rgba(14, 30, 37, 0.32) 7px 7px 8px 0px;
}

.interviews-box h2 {
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interviews-box {
  padding-bottom: 10px;
  height: 43rem;
}

.interview-scrollable {
  height: 37rem;
  overflow: auto;
}

.interview:hover {
  background-color: #eaeaea;
}

.candidate {
  font-weight: bold;
  margin-right: 10px;
}

.edit-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #0056b3;
}

.edit-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

.button-74 {
  background-color: #a79fce;
  border: #3e3298;
  border-radius: 30px;
  box-shadow: #3e3298 4px 4px 0 0;
  color: #3e3298;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: Satoshi;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-74:hover {
  background-color: #fff;
}

.button-74:active {
  box-shadow: #3e3298 2px 2px 0 0;
  transform: translate(2px, 2px);
}

.button-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #3e3298;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  font-family: Satoshi;
}

.button-74-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: relative;
}

h1.chart-label {
  font-family: Satoshi;
  font-size: 30px;
  font-weight: 600;
  line-height: 0.5;
  margin-left: 22px;
  margin-right: 22px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: #fff;
}

h1.job-header {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
  color: #fff;
}

.job-postings-container {
  font-family: "Satoshi";
  font-size: 18px;
  font-weight: 600;
  line-height: 0.5;
  color: var(--grey-900, #212121);
  position: relative;
  padding: 15px;
  top: 40rem;
  right: 105rem;
  background: var(--amber-50, #162e9e);
  /* Use custom CSS variable for background color */
  border: 1px solid #000000;
  border-radius: 15px;
  width: 28rem;
  display: inline-block;
  overflow: hidden;
  box-shadow: rgba(14, 30, 37, 0.12) 10px 14px 14px 0px,
    rgba(14, 30, 37, 0.32) 7px 7px 8px 0px;
}

.job-postings-container::before,
.job-postings-container::after,
.circle-shape {
  content: "";
  position: absolute;
  width: 211px;
  height: 211px;
  border-radius: 100%;
  border: 19px var(--blue-500, #2196f3);
}

.job-postings-container::before {
  top: -105px;
  left: -105px;
  border: 10px solid var(--blue-500, #2196f3);
}

.job-postings-container::after {
  bottom: -127px;
  right: -124px;
  border: 10px solid var(--blue-500, #2196f3);
}

.shape1-job {
  top: 31rem;
  right: -2rem;
  border: 3px solid var(--blue-500, #2196f3);
}

.shape2-job {
  bottom: 28rem;
  left: -4rem;
  border: 3px solid var(--blue-500, #2196f3);
}

@media only screen and (max-width: 390px) {
  .recruitment-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -1rem;
    left: -1rem;
  }

  .recruitment-tile {
    font-size: 16px;
    position: relative;
    left: 93px;
    font-family: Satoshi;
    font-weight: 700;
    color: #451397;
    text-align: center;
  }

  .sources-container {
    background: #6c80dc;
    border: 1px solid rgba(76, 0, 229, 0.38);
    border-radius: 20px;
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    position: absolute;
    color: #fff;
    top: 88px;
    width: 20.5rem;
    left: 2rem;
    box-shadow: rgba(14, 30, 37, 0.12) -10px 7px 14px 0px,
      rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
  }

  .label-progress {
    font-size: 15px;
    font-family: Satoshi;
    font-weight: 700;
    color: #451397;
    padding-top: 20px;
    padding-left: 18rem;
  }

  .chart-container-1 {
    position: relative;
    max-height: 31rem;
    top: 95rem;
    left: -77.7rem;
    border: solid 1px rgba(100, 37, 197, 0.3411764706);
    border-radius: 15px;
    width: 21.9rem;
    background: #451397;
    box-shadow: rgba(14, 30, 37, 0.12) -10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
    overflow: hidden;
  }

  #q-graph thead th.sent {
    top: 250px;
    left: 0;
    line-height: 2;
  }

  #q-graph thead th.paid {
    top: 19.75em;
    line-height: 2;
    left: 0;
  }

  .month {
    position: relative;
    top: 6rem;
    left: 5rem;
  }

  .week {
    position: relative;
    top: 4rem;
    left: 12.5rem;
  }

  .interviews-box-container {
    position: relative;
    top: 168.4rem;
    left: -55.4rem;
    width: 22.4rem;
  }

  .tile {
    background-color: #3e3298;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    width: 135px;
    font-family: Satoshi;
    font-size: 15px;
    font-weight: 600;
    color: aliceblue;
    transition: transform 0.3s ease-in-out;
  }

  .tiles-container {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 20px;
    max-width: 31rem;
    position: relative;
    left: 18rem;
    bottom: 6.4rem;
    align-content: center;
  }

  .tile-appbox {
    display: inline-block;
    justify-content: center;
    padding: 10px;
    position: relative;
    left: -11.7rem;
    bottom: -27rem;
    max-width: 47%;
  }

  .small-box-1,
  .small-box-2,
  .small-box-3,
  .small-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 83px;
    transition: transform 0.3s ease-in-out;
  }

  .job-postings-container {
    font-family: "Satoshi";
    font-size: 18px;
    font-weight: 600;
    line-height: 0.5;
    color: var(--grey-900, #212121);
    position: relative;
    padding: 15px;
    top: 52rem;
    height: 24rem;
    right: 102rem;
    background: var(--amber-50, #162e9e);
    border: 1px solid #000000;
    border-radius: 15px;
    width: 28rem;
    display: inline-block;
    overflow: hidden;
    box-shadow: rgba(14, 30, 37, 0.12) 10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) 7px 7px 8px 0px;
  }

  .hiring-positions-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 16rem;
    padding: 10px;
    margin: 10px;
    background: #fff;
    border-radius: 15px;
    color: #000;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    border: 1px solid #ffffff;
  }

  .interviews-box-container {
    position: relative;
    top: 164.4rem;
    left: -55.4rem;
    width: 22.4rem;
  }

  .small-box-2::after {
    left: -10px;
  }

  .small-box-2::before {
    content: "";
    position: absolute;
    top: 12px;
    bottom: 0;
    left: 97px;
    width: 1px;
    height: 60px;
    background-color: #ececec;
  }

  p.label-app-2 {
    position: relative;
    top: 9px;
    left: -4px;
    font-size: 11px;
  }

  #q-graph .bar {
    width: 4px;
    border: 1px solid;
    border-bottom: none;
    color: #000;
  }

  #q-graph #q2 {
    left: 36px;
  }

  #q-graph #q3 {
    left: 70px;
  }

  #q-graph #q4 {
    left: 105px;
  }

  #q-graph #q5 {
    left: 140px;
  }

  #q-graph #q6 {
    left: 175px;
  }

  #q-graph #q7 {
    left: 210px;
    border-right: none;
  }

  #q-graph .paid {
    left: 20px;
    background-color: #ecff62;
    border-color: transparent;
  }

  #ticks .tick {
    position: relative;
    border-bottom: 1px dotted #c4c4c4;
    width: 256px;
  }

  #q-graph tr,
  #q-graph th,
  #q-graph td {
    position: absolute;
    bottom: 0;
    width: 37px;
    z-index: 2;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .chart-container {
    margin-left: 58px;
    margin-bottom: -70px;
    margin-right: 27rem;
  }
}

@media screen and (min-width: 393px) and (max-width: 412px) {
  .recruitment-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -1rem;
    left: -1rem;
  }

  .recruitment-tile {
    font-size: 16px;
    position: relative;
    left: 93px;
    font-family: Satoshi;
    font-weight: 700;
    color: #451397;
    text-align: center;
  }

  .sources-container {
    background: #6c80dc;
    border: 1px solid rgba(76, 0, 229, 0.38);
    border-radius: 20px;
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    position: absolute;
    color: #fff;
    top: 88px;
    width: 20.5rem;
    left: 2rem;
    box-shadow: rgba(14, 30, 37, 0.12) -10px 7px 14px 0px,
      rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
  }

  .label-progress {
    font-size: 15px;
    font-family: Satoshi;
    font-weight: 700;
    color: #451397;
    padding-top: 20px;
    padding-left: 18rem;
  }

  .chart-container-1 {
    position: relative;
    max-height: 31rem;
    top: 95rem;
    left: -77.7rem;
    border: solid 1px rgba(100, 37, 197, 0.3411764706);
    border-radius: 15px;
    width: 21.9rem;
    background: #451397;
    box-shadow: rgba(14, 30, 37, 0.12) -10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
    overflow: hidden;
  }

  #q-graph thead th.sent {
    top: 250px;
    left: 0;
    line-height: 2;
  }

  #q-graph thead th.paid {
    top: 19.75em;
    line-height: 2;
    left: 0;
  }

  .month {
    position: relative;
    top: 6rem;
    left: 5rem;
  }

  .week {
    position: relative;
    top: 4rem;
    left: 12.5rem;
  }

  .interviews-box-container {
    position: relative;
    top: 168.4rem;
    left: -55.4rem;
    width: 22.4rem;
  }

  .tile {
    background-color: #3e3298;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    width: 135px;
    font-family: Satoshi;
    font-size: 15px;
    font-weight: 600;
    color: aliceblue;
    transition: transform 0.3s ease-in-out;
  }

  .tiles-container {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 10px;
    max-width: 20rem;
    position: relative;
    left: -12rem;
    bottom: -43.6rem;
    align-content: center;
  }

  .tile-appbox {
    display: inline-block;
    justify-content: center;
    padding: 10px;
    position: relative;
    left: -32.7rem;
    bottom: -71rem;
    max-width: 48%;
  }

  .small-box-1,
  .small-box-2,
  .small-box-3,
  .small-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 83px;
    transition: transform 0.3s ease-in-out;
  }

  .job-postings-container {
    font-family: "Satoshi";
    font-size: 15px;
    font-weight: 600;
    line-height: 0.5;
    color: var(--grey-900, #212121);
    position: relative;
    padding: 15px;
    top: 127rem;
    right: 99.7rem;
    background: var(--amber-50, #162e9e);
    border: 1px solid #000000;
    border-radius: 15px;
    width: 20.1rem;
    display: inline-block;
    overflow: hidden;
    box-shadow: rgba(14, 30, 37, 0.12) 10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) 7px 7px 8px 0px;
  }

  .hiring-positions-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 16rem;
    padding: 10px;
    margin: 10px;
    background: #fff;
    border-radius: 15px;
    color: #000;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    border: 1px solid #ffffff;
  }

  .interviews-box-container {
    position: relative;
    top: 164.4rem;
    left: -55.4rem;
    width: 22.4rem;
  }

  .small-box-2::after {
    left: -10px;
  }

  .small-box-2::before {
    content: "";
    position: absolute;
    top: 12px;
    bottom: 0;
    left: 97px;
    width: 1px;
    height: 60px;
    background-color: #ececec;
  }

  p.label-app-2 {
    position: relative;
    top: 9px;
    left: -4px;
    font-size: 11px;
  }

  #q-graph .bar {
    width: 4px;
    border: 1px solid;
    border-bottom: none;
    color: #000;
  }

  #q-graph #q2 {
    left: 36px;
  }

  #q-graph #q3 {
    left: 70px;
  }

  #q-graph #q4 {
    left: 105px;
  }

  #q-graph #q5 {
    left: 140px;
  }

  #q-graph #q6 {
    left: 175px;
  }

  #q-graph #q7 {
    left: 210px;
    border-right: none;
  }

  #q-graph .paid {
    left: 20px;
    background-color: #ecff62;
    border-color: transparent;
  }

  #ticks .tick {
    position: relative;
    border-bottom: 1px dotted #c4c4c4;
    width: 256px;
  }

  #q-graph tr,
  #q-graph th,
  #q-graph td {
    position: absolute;
    bottom: 0;
    width: 37px;
    z-index: 2;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .chart-container {
    margin-left: 58px;
    margin-bottom: -70px;
    margin-right: 27rem;
  }
}

@media screen and (min-width: 414px) and (max-width: 767px) {
  .recruitment-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -1rem;
    left: -1rem;
  }

  .recruitment-tile {
    font-size: 16px;
    position: relative;
    left: 93px;
    font-family: Satoshi;
    font-weight: 700;
    color: #451397;
    text-align: center;
  }

  .sources-container {
    background: #6c80dc;
    border: 1px solid rgba(76, 0, 229, 0.38);
    border-radius: 20px;
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    position: absolute;
    color: #fff;
    top: 88px;
    width: 21.5rem;
    left: 2rem;
    box-shadow: rgba(14, 30, 37, 0.12) -10px 7px 14px 0px,
      rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
  }

  .label-progress {
    font-size: 15px;
    font-family: Satoshi;
    font-weight: 700;
    color: #451397;
    padding-top: 20px;
    padding-left: 19rem;
  }

  .chart-container-1 {
    position: relative;
    max-height: 31rem;
    top: 94rem;
    left: -78.9rem;
    border: solid 1px rgba(100, 37, 197, 0.3411764706);
    border-radius: 15px;
    width: 23.1rem;
    background: #451397;
    box-shadow: rgba(14, 30, 37, 0.12) -10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
    overflow: hidden;
  }

  #q-graph thead th.sent {
    top: 250px;
    left: 0;
    line-height: 2;
  }

  #q-graph thead th.paid {
    top: 19.75em;
    line-height: 2;
    left: 0;
  }

  .month {
    position: relative;
    top: 6rem;
    left: 5rem;
  }

  .week {
    position: relative;
    top: 4rem;
    left: 12.5rem;
  }

  .interviews-box-container {
    position: relative;
    top: 168.4rem;
    left: -55.4rem;
    width: 22.4rem;
  }

  .tile {
    background-color: #3e3298;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    width: 135px;
    font-family: Satoshi;
    font-size: 15px;
    font-weight: 600;
    color: aliceblue;
    transition: transform 0.3s ease-in-out;
  }

  .tiles-container {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 20px;
    max-width: 20rem;
    position: relative;
    left: -12rem;
    bottom: -43.6rem;
    align-content: center;
  }

  .tile-appbox {
    display: inline-block;
    justify-content: center;
    padding: 10px;
    position: relative;
    left: -32.7rem;
    bottom: -71rem;
    max-width: 48%;
  }

  .small-box-1,
  .small-box-2,
  .small-box-3,
  .small-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90px;
    transition: transform 0.3s ease-in-out;
  }

  .job-postings-container {
    font-family: "Satoshi";
    font-size: 15px;
    font-weight: 600;
    line-height: 0.5;
    color: var(--grey-900, #212121);
    position: relative;
    padding: 15px;
    top: 127rem;
    right: 102.1rem;
    background: var(--amber-50, #162e9e);
    border: 1px solid #000000;
    border-radius: 15px;
    width: 21.2rem;
    display: inline-block;
    overflow: hidden;
    box-shadow: rgba(14, 30, 37, 0.12) 10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) 7px 7px 8px 0px;
  }

  .hiring-positions-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 16rem;
    padding: 10px;
    margin: 10px;
    background: #fff;
    border-radius: 15px;
    color: #000;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    border: 1px solid #ffffff;
  }

  .interviews-box-container {
    position: relative;
    top: 164.4rem;
    left: -56.4rem;
  }

  .interviews-box {
    padding-bottom: 10px;
    height: 31rem;
    width: 21.7rem;
  }

  .small-box-2::after {
    left: -10px;
  }

  .small-box-2::before {
    content: "";
    position: absolute;
    top: 12px;
    bottom: 0;
    left: 97px;
    width: 1px;
    height: 60px;
    background-color: #ececec;
  }

  p.label-app-2 {
    position: relative;
    top: 9px;
    left: -4px;
    font-size: 11px;
  }

  #q-graph .bar {
    width: 4px;
    border: 1px solid;
    border-bottom: none;
    color: #000;
  }

  #q-graph #q2 {
    left: 36px;
  }

  #q-graph #q3 {
    left: 70px;
  }

  #q-graph #q4 {
    left: 105px;
  }

  #q-graph #q5 {
    left: 140px;
  }

  #q-graph #q6 {
    left: 175px;
  }

  #q-graph #q7 {
    left: 210px;
    border-right: none;
  }

  #q-graph .paid {
    left: 20px;
    background-color: #ecff62;
    border-color: transparent;
  }

  #ticks .tick {
    position: relative;
    border-bottom: 1px dotted #c4c4c4;
    width: 256px;
  }

  #q-graph tr,
  #q-graph th,
  #q-graph td {
    position: absolute;
    bottom: 0;
    width: 37px;
    z-index: 2;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .chart-container {
    margin-left: 58px;
    margin-bottom: -70px;
    margin-right: 27rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 912px) {
  .dashboard {
    background-color: rgb(238, 242, 246);
    min-height: calc(226vh - 150px);
    flex-grow: 1;
    padding: 20px;
    max-width: 100%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 12px 12px 0px 0px;
    z-index: -999;
    transition: width 0.3s ease-in-out;
  }

  .dashboard-container-recuitment {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    height: 100%;
    position: relative;
    top: -38.6rem;
    left: -17rem;
    margin-right: auto;
    flex-direction: column;
    z-index: 1;
    transition: width 0.5s ease-in-out;
  }

  .recruitment-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -1rem;
    left: 1rem;
  }

  .job-postings-container {
    font-family: "Satoshi";
    font-size: 15px;
    font-weight: 600;
    line-height: 0.5;
    color: var(--grey-900, #212121);
    position: relative;
    padding: 15px;
    top: 52rem;
    height: 24rem;
    right: 102rem;
    background: var(--amber-50, #162e9e);
    border: 1px solid #000000;
    border-radius: 15px;
    width: 28rem;
    display: inline-block;
    overflow: hidden;
    box-shadow: rgba(14, 30, 37, 0.12) 10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) 7px 7px 8px 0px;
  }

  .hiring-positions-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 16rem;
    padding: 10px;
    margin: 10px;
    background: #fff;
    border-radius: 15px;
    color: #000;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    border: 1px solid #ffffff;
  }

  #ticks .tick {
    position: relative;
    border-bottom: 1px dotted #c4c4c4;
    width: 380px;
  }

  .interviews-box-container {
    position: relative;
    top: 52.4rem;
    left: -38.1rem;
  }

  .interviews-box {
    padding-bottom: 10px;
    height: 31rem;
    width: 18.7rem;
  }

  .small-box-2::after {
    left: -10px;
  }

  .small-box-2::before {
    content: "";
    position: absolute;
    top: 12px;
    bottom: 0;
    left: 150px;
    width: 1px;
    height: 60px;
    background-color: #ececec;
  }

  p.label-app-2 {
    position: relative;
    top: 9px;
    left: -4px;
    font-size: 11px;
  }

  #q-graph {
    display: block;
    position: relative;
    width: 25rem;
    height: 300px;
    margin: 1.1em 0 0;
    padding: 0;
    background: transparent;
    font-size: 11px;
  }

  #q-graph .bar {
    width: 15px;
    border: 1px solid;
    border-bottom: none;
    color: #000;
  }

  #q-graph #q2 {
    left: 55px;
  }

  #q-graph #q3 {
    left: 110px;
  }

  #q-graph #q4 {
    left: 165px;
  }

  #q-graph #q5 {
    left: 220px;
  }

  #q-graph #q6 {
    left: 275px;
  }

  #q-graph #q7 {
    left: 330px;
    border-right: none;
  }

  #q-graph .paid {
    left: 25px;
    background-color: #ecff62;
    border-color: transparent;
  }

  #q-graph .sent {
    left: 5px;
    background-color: #0092ff;
    border-color: transparent;
  }

  #ticks .tick {
    position: relative;
    border-bottom: 1px dotted #c4c4c4;
    width: 256px;
  }

  #q-graph tr,
  #q-graph th,
  #q-graph td {
    position: absolute;
    bottom: 0;
    width: 50px;
    z-index: 2;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .chart-container {
    margin-left: 70px;
    margin-bottom: -70px;
    margin-right: 27rem;
  }

  .chart-container-1 {
    position: relative;
    max-height: 26rem;
    top: 109rem;
    left: -90.7rem;
    border: solid 1px rgba(100, 37, 197, 0.3411764706);
    border-radius: 15px;
    width: 42rem;
    background: #451397;
    box-shadow: rgba(14, 30, 37, 0.12) -10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
    overflow: hidden;
  }

  .tiles-container {
    display: grid;
    grid-template-columns: repeat(1, 4fr);
    grid-gap: 20px;
    max-width: 31rem;
    position: relative;
    left: 13rem;
    bottom: -6.6rem;
    align-content: center;
  }

  .tile-appbox {
    display: block;
    justify-content: center;
    padding: 10px;
    position: relative;
    left: -24.9rem;
    bottom: -85rem;
    max-width: 47%;
  }
}

.dashboard.panel-open {
  max-width: 60%;
  transition: max-width 0.6s ease-in-out;
  min-height: calc(313vh - 150px);

  .dashboard {
    background-color: rgb(238, 242, 246);
    flex-grow: 1;
    padding: 20px;
    max-width: 100%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 12px 12px 0px 0px;
    z-index: 1;
    transition: width 0.6s ease-in-out, max-width 0.6s ease-in-out;
    overflow: hidden;
  }

  .recruitment-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -1rem;
    left: 2rem;
  }

  .recruitment-tile {
    font-size: 16px;
    position: relative;
    left: 93px;
    font-family: Satoshi;
    font-weight: 700;
    color: #451397;
    text-align: center;
  }

  .sources-container {
    background: #6c80dc;
    border: 1px solid rgba(76, 0, 229, 0.38);
    border-radius: 20px;
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    position: absolute;
    color: #fff;
    top: 88px;
    width: 21.5rem;
    left: 2rem;
    box-shadow: rgba(14, 30, 37, 0.12) -10px 7px 14px 0px,
      rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
  }

  .label-progress {
    font-size: 15px;
    font-family: Satoshi;
    font-weight: 700;
    color: #451397;
    padding-top: 20px;
    padding-left: 19rem;
  }

  .chart-container-1 {
    position: relative;
    max-height: 31rem;
    top: 94rem;
    left: -78.9rem;
    border: solid 1px rgba(100, 37, 197, 0.3411764706);
    border-radius: 15px;
    width: 23.1rem;
    background: #451397;
    box-shadow: rgba(14, 30, 37, 0.12) -10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
    overflow: hidden;
  }

  #q-graph thead th.sent {
    top: 250px;
    left: 0;
    line-height: 2;
  }

  #q-graph thead th.paid {
    top: 19.75em;
    line-height: 2;
    left: 0;
  }

  .month {
    position: relative;
    top: 6rem;
    left: 5rem;
  }

  .week {
    position: relative;
    top: 4rem;
    left: 12.5rem;
  }

  .interviews-box-container {
    position: relative;
    top: 168.4rem;
    left: -55.4rem;
    width: 22.4rem;
  }

  .tile {
    background-color: #3e3298;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    width: 135px;
    font-family: Satoshi;
    font-size: 15px;
    font-weight: 600;
    color: aliceblue;
    transition: transform 0.3s ease-in-out;
  }

  .tiles-container {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 20px;
    max-width: 20rem;
    position: relative;
    left: -12rem;
    bottom: -45.6rem;
    align-content: center;
  }

  .tile-appbox {
    display: inline-block;
    justify-content: center;
    padding: 10px;
    position: relative;
    left: -32.7rem;
    bottom: -71rem;
    max-width: 48%;
  }

  .small-box-1,
  .small-box-2,
  .small-box-3,
  .small-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90px;
    transition: transform 0.3s ease-in-out;
  }

  .job-postings-container {
    font-family: "Satoshi";
    font-size: 15px;
    font-weight: 600;
    line-height: 0.5;
    color: var(--grey-900, #212121);
    position: relative;
    padding: 15px;
    top: 127rem;
    right: 102.1rem;
    background: var(--amber-50, #162e9e);
    border: 1px solid #000000;
    border-radius: 15px;
    width: 21.2rem;
    display: inline-block;
    overflow: hidden;
    box-shadow: rgba(14, 30, 37, 0.12) 10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) 7px 7px 8px 0px;
  }

  .hiring-positions-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 16rem;
    padding: 10px;
    margin: 10px;
    background: #fff;
    border-radius: 15px;
    color: #000;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    border: 1px solid #ffffff;
  }

  .interviews-box-container {
    position: relative;
    top: 160.4rem;
    left: -56.4rem;
  }

  .interviews-box {
    padding-bottom: 10px;
    height: 31rem;
    width: 21.7rem;
  }

  .small-box-2::after {
    left: -10px;
  }

  .small-box-2::before {
    content: "";
    position: absolute;
    top: 12px;
    bottom: 0;
    left: 97px;
    width: 1px;
    height: 60px;
    background-color: #ececec;
  }

  p.label-app-2 {
    position: relative;
    top: 9px;
    left: -4px;
    font-size: 11px;
  }

  #q-graph .bar {
    width: 4px;
    border: 1px solid;
    border-bottom: none;
    color: #000;
  }

  #q-graph #q2 {
    left: 36px;
  }

  #q-graph #q3 {
    left: 70px;
  }

  #q-graph #q4 {
    left: 105px;
  }

  #q-graph #q5 {
    left: 140px;
  }

  #q-graph #q6 {
    left: 175px;
  }

  #q-graph #q7 {
    left: 210px;
    border-right: none;
  }

  #q-graph .paid {
    left: 20px;
    background-color: #ecff62;
    border-color: transparent;
  }

  #ticks .tick {
    position: relative;
    border-bottom: 1px dotted #c4c4c4;
    width: 256px;
  }

  #q-graph tr,
  #q-graph th,
  #q-graph td {
    position: absolute;
    bottom: 0;
    width: 37px;
    z-index: 2;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .chart-container {
    margin-left: 58px;
    margin-bottom: -70px;
    margin-right: 27rem;
  }
}

@media only screen and (min-width: 913px) and (max-width: 1199px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1934px) {
  .dashboard-container-recuitment {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    height: 100%;
    position: relative;
    top: -38.6rem;
    left: -17rem;
    margin-right: auto;
    flex-direction: column;
    z-index: 1;
    transition: width 0.5s ease-in-out;
  }

  .recruitment-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -1rem;
    left: 1rem;
  }
  .job-postings-container {
    font-family: "Satoshi";
    font-size: 18px;
    font-weight: 600;
    line-height: 0.5;
    color: var(--grey-900, #212121);
    position: relative;
    padding: 15px;
    top: 52rem;
    height: 24rem;
    right: 102rem;
    background: var(--amber-50, #162e9e);
    border: 1px solid #000000;
    border-radius: 15px;
    width: 28rem;
    display: inline-block;
    overflow: hidden;
    box-shadow: rgba(14, 30, 37, 0.12) 10px 14px 14px 0px,
      rgba(14, 30, 37, 0.32) 7px 7px 8px 0px;
  }

  .tile-appbox {
    display: inline-block;
    justify-content: center;
    padding: 10px;
    position: relative;
    left: -11.2rem;
    bottom: -27rem;
    max-width: 47%;
  }
}
