@import url("https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

.modal-overlay-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it is displayed above other elements */
  opacity: 0; /* Start with opacity 0, will be transitioned to 1 when modal opens */
  pointer-events: none; /* Hide the modal from capturing interactions initially */
  transition: opacity 0.3s ease; /* Opacity transition for smooth display */
}
.modal-content-1 {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 95rem;
  height: 40rem;
  z-index: 999;
}

.modal-body-1{
  padding: 20px;
}

.modal-overlay-1.open {
  opacity: 1; /* Show the modal with opacity 1 when it's open */
  pointer-events: auto; /* Allow the modal to capture interactions */
}

.close-icon-1 {
  cursor: pointer;
  position: relative;
  top: -33px;
  left: 94rem;
  color: #fff;
  z-index: 999;
}

.modal-header-1 {
  text-align: center;
  padding: 20px 0;
  background-color: #3a3672;
  border-radius: 15px 15px 0 0;
  position: relative;
  top: -61px;
}

.modal-header-1 h2 {
  font-family: 'Satoshi', sans-serif;
  font-size: 2.5rem;
  color: #fff; /* Header text color */
}

.applicant-table-1 {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.applicant-table-1 th, .applicant-table-1 td {
  border: 1px solid #ddd; /* Table cell border color */
  padding: 10px;
  text-align: left;
}

.applicant-table-1 th {
  background-color: #f5f5f5; /* Table header background color */
}

.applicant-table-1 tr:nth-child(even) {
  background-color: #f2f2f2; /* Alternate row background color */
}

.applicant-table-1 th, .applicant-table td {
  font-family: 'satoshi', sans-serif;
}

.label-pooling {
  font-family: "satoshi";
  font-size: 25px;
  font-weight: 800;
  line-height: 0.5;
}


