@import url('https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap');

.dashboard-page {
  display: flex;
  align-items: center;
}

.dashboard {
  background-color: rgb(238, 242, 246);
  width: 97%;
  min-height: calc(150vh - 150px);
  flex-grow: 1;
  padding: 20px;
  max-width: 100%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: 0;
  border-radius: 12px 12px 0px 0px;
  transition: width 0.3s ease-in-out;

}

.dashboard.half-width {
  width: 85%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 55px;
  z-index: 1;
}

.dashboard-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  position: relative;
  top: -5rem;
  margin-right: auto;

  .banner-container {
    z-index: 9999;
    /* Set a high z-index value to bring the banner to the front */
  }
}

.dashboard-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: none;
  transition: transform 0.3s ease-in-out;
  /* Add transition for non-hover state */

  /* Added hover effect */
  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease-in-out;
  }
}

.tiles {
  width: 380px;
  height: 169px;
  border-radius: 12px;
  border: none;
  background-color: rgb(94, 53, 177);
  color: rgb(255, 255, 255);
  overflow: hidden;
  position: relative;
  box-shadow: none;
  background-image: none;
  padding: 18px;
  /* Added transition for smoother hover effect */
  transition: transform 0.5s linear;
  cursor: pointer;

  /* Updated position for the shapes on hover */
  &:hover::before {
    top: -74px;
    right: -104px;
  }

  &:hover::after {
    top: -94px;
    right: -195px;
  }
}

.tiles::before {
  content: "";
  position: absolute;
  top: -124px;
  right: -4px;
  transition: top 0.5s ease-in-out, right 0.5s ease-in-out;
  /* Add transition for the shapes on hover */
}

.tiles::after {
  content: "";
  position: absolute;
  top: -104px;
  right: -95px;
  transition: top 0.5s ease-in-out, right 0.5s ease-in-out;
  /* Add transition for the shapes on hover */
}

.tiles::before {
  width: 210px;
  height: 210px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.tiles::after {
  width: 211px;
  height: 211px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}

.tiles .title-tile {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}


@media only screen and (max-width: 390px) {

  .sidepanel-backdrop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
  }

  .dashboard-container {
    display: flex;
    width: 340px;
    height: 100%;
    position: relative;
    top: -12rem;
    margin-right: auto;
    flex-direction: column;
    z-index: -999;
  }

  .tiles {
    width: 380px;
    height: 98px;
    border-radius: 12px;
    border: none;
    background-color: rgb(94, 53, 177);
    color: rgb(255, 255, 255);
    overflow: hidden;
    position: relative;
    box-shadow: none;
    background-image: none;
    padding: 18px;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
    cursor: pointer;

    /* Updated position for the shapes on hover */
    &:hover::before {
      top: -74px;
      right: -104px;
    }

    &:hover::after {
      top: -94px;
      right: -195px;
    }
  }

  .dashboard {
    background-color: rgb(238, 242, 246);
    min-height: calc(150vh - 150px);
    flex-grow: 1;
    padding: 20px;
    max-width: 100%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 12px 12px 0px 0px;
    z-index: -999;
    transition: width 0.3s ease-in-out;
  }

  .panel-content {
    padding: 8px;
    background: #fff;
    height: 100vh;
  }

  .sidepanel {
    position: fixed;
    top: 59px;
    left: 0;
    width: 250px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
  }

  button.btn-dashboard.active,
  .btn-recruitment,
  .btn-cdoc,
  .btn-compensation,
  .btn-ER,
  .btn-CP,
  .btn-LD,
  .btn-payroll {
    height: 50px;
  }

}


@media screen and (min-width: 393px) and (max-width: 412px) {
  .sidepanel-backdrop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
  }

  .dashboard-container {
    display: flex;
    width: 340px;
    height: 100%;
    position: relative;
    top: -12rem;
    margin-right: auto;
    flex-direction: column;
    z-index: -999;
  }

  .tiles {
    width: 380px;
    height: 98px;
    border-radius: 12px;
    border: none;
    background-color: rgb(94, 53, 177);
    color: rgb(255, 255, 255);
    overflow: hidden;
    position: relative;
    box-shadow: none;
    background-image: none;
    padding: 18px;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
    cursor: pointer;


    /* Updated position for the shapes on hover */
    &:hover::before {
      top: -74px;
      right: -104px;
    }

    &:hover::after {
      top: -94px;
      right: -195px;
    }
  }

  .dashboard {
    background-color: rgb(238, 242, 246);
    min-height: calc(150vh - 150px);
    flex-grow: 1;
    padding: 20px;
    max-width: 100%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 12px 12px 0px 0px;
    z-index: -999;
    transition: width 0.3s ease-in-out;
  }

  .panel-content {
    padding: 8px;
    background: #fff;
    height: 100vh;
  }

  .sidepanel {
    position: fixed;
    top: 59px;
    left: 0;
    width: 250px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
  }

  button.btn-dashboard.active,
  .btn-recruitment,
  .btn-cdoc,
  .btn-compensation,
  .btn-ER,
  .btn-CP,
  .btn-LD,
  .btn-payroll {
    height: 50px;
  }
}

@media screen and (min-width: 414px) and (max-width: 767px) {
  .sidepanel-backdrop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
  }

  .dashboard-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    top: -12rem;
    margin-right: auto;
    flex-direction: column;
    z-index: -999;
  }

  .tiles {
    width: 380px;
    height: 98px;
    border-radius: 12px;
    border: none;
    background-color: rgb(94, 53, 177);
    color: rgb(255, 255, 255);
    overflow: hidden;
    position: relative;
    box-shadow: none;
    background-image: none;
    padding: 18px;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
    cursor: pointer;

    /* Updated position for the shapes on hover */
    &:hover::before {
      top: -74px;
      right: -104px;
    }

    &:hover::after {
      top: -94px;
      right: -195px;
    }
  }

  .dashboard {
    background-color: rgb(238, 242, 246);
    min-height: calc(150vh - 150px);
    flex-grow: 1;
    max-width: 90%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 12px 12px 0px 0px;
    z-index: -999;
    transition: width 0.3s ease-in-out;
  }

  .panel-content {
    padding: 8px;
    background: #fff;
    height: 100vh;
  }

  .sidepanel {
    position: fixed;
    top: 59px;
    left: 0;
    width: 250px;
    transition: transform 0.2s ease-in-out;
    transform: translateX(-100%);
  }

  button.btn-dashboard.active,
  .btn-recruitment,
  .btn-cdoc,
  .btn-compensation,
  .btn-ER,
  .btn-CP,
  .btn-LD,
  .btn-payroll {
    height: 50px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 912px) {

  .dashboard-container.flex {
    display: flex;
    flex-wrap: wrap;
  }

  .dashboard-container.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 340px;
    height: 100%;
    position: relative;
    top: -12rem;
    margin-right: auto;
    flex-direction: column;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
    z-index: -999;
  }

  .dashboard.panel-open {
    max-width: 60%;
    transition: max-width 0.6s ease-in-out;
  }

  .dashboard.panel-closed {
    max-width: 100%;
    transition: max-width 0.6s ease-in-out;

    .tiles {
      width: 360px;
      height: 100px;
      border-radius: 12px;
      border: none;
      background-color: rgb(94, 53, 177);
      color: rgb(255, 255, 255);
      overflow: hidden;
      position: relative;
      box-shadow: none;
      background-image: none;
      padding: 18px;
      transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .dashboard-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 340px;
    height: 100%;
    position: relative;
    top: -12rem;
    margin-right: auto;
    flex-direction: column;
    z-index: 1;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
  }

  .tiles {
    width: 460px;
    height: 100px;
    border-radius: 12px;
    border: none;
    background-color: rgb(94, 53, 177);
    color: rgb(255, 255, 255);
    overflow: hidden;
    position: relative;
    box-shadow: none;
    background-image: none;
    padding: 18px;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
    cursor: pointer;



    /* Updated position for the shapes on hover */
    &:hover::before {
      top: -74px;
      right: -104px;
    }

    &:hover::after {
      top: -94px;
      right: -195px;
    }
  }

  .dashboard {
    background-color: rgb(238, 242, 246);
    min-height: calc(150vh - 150px);
    flex-grow: 1;
    padding: 20px;
    max-width: 100%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 12px 12px 0px 0px;
    z-index: 1;
    transition: width 0.6s ease-in-out, max-width 0.6s ease-in-out;
    overflow: hidden;
  }

  .panel-content {
    padding: 8px;
    background: #fff;
    height: 100vh;
  }

  .sidepanel {
    position: fixed;
    top: 59px;
    left: 0;
    width: 250px;
    transition: transform 0.5s ease-in-out;
    transform: translateX(-100%);
  }

  button.btn-dashboard.active,
  .btn-recruitment,
  .btn-cdoc,
  .btn-compensation,
  .btn-ER,
  .btn-CP,
  .btn-LD,
  .btn-payroll {
    height: 50px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {

  .right-section {
    display: flex;
    align-items: center;
    margin-left: 87rem;
  }

  .dashboard-container.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 340px;
    height: 100%;
    position: relative;
    top: -11.6rem;
    margin-right: auto;
    flex-direction: column;
    z-index: 1;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
  }

  .dashboard.panel-open {
    max-width: 75%;
    transition: max-width 0.6s ease-in-out;
  }

  .dashboard.panel-closed {
    max-width: 100%;
    transition: max-width 0.6s ease-in-out;

    .tiles {
      width: 375px;
      height: 100px;
      border-radius: 12px;
      border: none;
      background-color: rgb(94, 53, 177);
      color: rgb(255, 255, 255);
      overflow: hidden;
      position: relative;
      box-shadow: none;
      background-image: none;
      padding: 18px;
      transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .dashboard-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
    height: 100%;
    position: relative;
    top: -8rem;
    margin-right: auto;
    flex-direction: column;
    z-index: 1;
    transition: width 0.6s ease-in-out;
  }

  .tiles {
    width: 440px;
    height: 100px;
    border-radius: 12px;
    border: none;
    background-color: rgb(94, 53, 177);
    color: rgb(255, 255, 255);
    overflow: hidden;
    position: relative;
    box-shadow: none;
    background-image: none;
    padding: 18px;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
    cursor: pointer;

    /* Updated position for the shapes on hover */
    &:hover::before {
      top: -74px;
      right: -104px;
    }

    &:hover::after {
      top: -94px;
      right: -195px;
    }
  }

  .dashboard {
    background-color: rgb(238, 242, 246);
    width: 97%;
    min-height: calc(150vh - 150px);
    flex-grow: 1;
    padding: 20px;
    position: relative;
    max-width: 81%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 12px 12px 0px 0px;
    transition: width 0.3s ease-in-out;

  }

  .panel-content {
    padding: 8px;
    background: #fff;
    height: 100vh;
  }

  .sidepanel.open {
    transform: translateX(0);
  }

  .sidepanel {
    position: fixed;
    top: 59px;
    left: 0;
    width: 250px;
    transition: transform 0.5s ease-in-out;
    transform: translateX(-100%);
  }

  button.btn-dashboard.active,
  .btn-recruitment,
  .btn-cdoc,
  .btn-compensation,
  .btn-ER,
  .btn-CP,
  .btn-LD,
  .btn-payroll {
    height: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1934px) {
  .right-section {
    display: flex;
    align-items: center;
    margin-left: 87rem;
}

  .dashboard.panel-open {
    max-width: 84.5%;
    transition: max-width 0.3s ease-in-out;
}

  .dashboard.panel-closed {
    max-width: 100%;
    transition: max-width 0.3s ease-in-out;

    .tiles {
      width: 375px;
      height: 100px;
      border-radius: 12px;
      border: none;
      background-color: rgb(94, 53, 177);
      color: rgb(255, 255, 255);
      overflow: hidden;
      position: relative;
      box-shadow: none;
      background-image: none;
      padding: 18px;
      transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .dashboard-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    height: 100%;
    position: relative;
    top: -11.6rem;
    margin-right: auto;
    flex-direction: column;
    z-index: 1;
    transition: width 0.5s ease-in-out;
  }

  .tiles {
    width: 440px;
    height: 100px;
    border-radius: 12px;
    border: none;
    background-color: rgb(94, 53, 177);
    color: rgb(255, 255, 255);
    overflow: hidden;
    position: relative;
    box-shadow: none;
    background-image: none;
    padding: 18px;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
    cursor: pointer;
  
    /* Updated position for the shapes on hover */
    &:hover::before {
      top: -74px;
      right: -104px;
    }
  
    &:hover::after {
      top: -94px;
      right: -195px;
    }
  }
  

  .dashboard {
    background-color: rgb(238, 242, 246);
    width: 97%;
    min-height: calc(150vh - 150px);
    flex-grow: 1;
    padding: 20px;
    position: relative;
    max-width: 81%;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 12px 12px 0px 0px;
    transition: width 0.3s ease-in-out;

  }

  .panel-content {
    padding: 8px;
    background: #fff;
    height: 100vh;
  }

  .sidepanel.open {
    transform: translateX(0);
  }

  .sidepanel {
    position: fixed;
    top: 59px;
    left: 0;
    width: 250px;
    transition: transform 0.5s ease-in-out;
    transform: translateX(-100%);
  }

  button.btn-dashboard.active,
  .btn-recruitment,
  .btn-cdoc,
  .btn-compensation,
  .btn-ER,
  .btn-CP,
  .btn-LD,
  .btn-payroll {
    height: 50px;
  }
}