.hiring-positions-box-container {
  border-radius: 15px;
  width: fit-content;
  display: inline-block;
  overflow: auto;
  position: relative;
  padding-top: 30px;
}

.hiring-positions-title {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  z-index: 1;
}

.hiring-positions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.hiring-positions-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 24rem;
  padding: 10px;
  margin: 10px;
  background: #fff;
  border-radius: 15px;
  color: #000;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  border: 1px solid #ffffff;
}

.hiring-positions-item:hover {
  border-color: rgb(217, 246, 0);
  transform: scale(1.05);
}

.hiring-positions-next-icon {
  font-size: 1.5rem;
}
