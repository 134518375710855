.sidepanel {
    position: fixed;
    top: 60px;
    left: 0;
    width: 250px;
    height: 100vh;
    transition: transform 0.2s ease-in-out;
    transform: translateX(-100%);
}

.sidepanel.open {
    transform: translateX(0);

}

.sidepanel-backdrop {
    display: none;
}

@media only screen and (max-width: 390px) {
    .sidepanel-backdrop {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-tap-highlight-color: transparent;
        z-index: -1;
    }
}



.label-dashboard {
    margin: 10px 0px 0.35em;
    font-family: Roboto, sans-serif;
    line-height: 1.66;
    display: block;
    color: rgb(18, 25, 38);
    font-size: 0.875rem;
    font-weight: 700;
    padding: 6px;
    text-transform: capitalize;
}

.panel-content {
    padding: 20px;
    background: #fff;
    height: inherit;
}

.panel-content button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    text-align: left;
    cursor: pointer;
    display: flex;
    padding: 14px 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color: var(--deep-purple-600, #5E35B1);
    font-size: 14px;
    font-family: Roboto;
    line-height: 20px;


}

.btn-recruitment.active,
.btn-dashboard.active {
    background-color: var(--deep-purple-50, #b191e1);
    color: #fff;
    /* Add any other styles you want for the active state */
}

.panel-content button:hover {
    border-radius: 12px;
    background: var(--deep-purple-50, #b191e1);
    ;
    color: #fff;
}

.btn-dashboard {
    border-radius: 12px;

}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {

    .sidepanel-backdrop {
       display: none;
    }
}

