/* styles.scss */
.droppable-column {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 10px;
  }

  .droppable-area {
    height: 365px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 2px dashed #aaa;
    padding: 10px;
    width: 100%;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;

    .tile-user {
      background-color: rgba(63, 30, 130, 0.72);
      border: 1px solid #999;
      padding: 5px;
      cursor: move;
      width: 100%;
      height: 68px;
      margin: 3px 0 3px 0;
      border-radius: 10px;
      font-family: Satoshi;
      font-size: 15px;
      font-weight: 600;
      line-height: 0.5;
      box-sizing: border-box;
      transition: transform 0.3s, background-color 0.3s, color 0.3s;
    }

    .tile-dropped {
      transform: scale(1.05);
      transition: transform 0.3s, background-color 0.3s, color 0.3s;
    }

    .picture-placeholder {
      display: flex;
      width: 50px;
      height: 50px;
      border: solid 0.5px;
      margin: 5px;
      position: relative;
      top: -3px;
      border-radius: 9px;
    }

    .name-user {
      display: flex;
      align-items: center;
      margin: 0;
      flex-direction: row;
      position: relative;
      top: -35px;
      left: 64px;
      color: #fff;
    }

    .position-user {
      position: relative;
      top: -26px;
      margin: 0;
      left: 190px;
      flex-direction: row;
      font-size: 10px;
      font-weight: 300;
      font-family: "Satoshi";
      color: #fff;
    }

    span.created-time {
      font-size: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      top: -68px;
      left: -4px;
      font-weight: 500;
      color: #fff;
      line-height: 0.5;
    }

    .tile-background-0-2 {
      background-color: #f0b86e;
    }
    
    .tile-background-3-5 {
      background-color: #35155d;
    }
    
    .tile-background-6 {
      background-color: #8cc0de;
    }
    
    .tile-text-0-2 {
      color: #000;
    }
    
    .tile-text-3-5 {
      color: #fff;
    }
    
    .tile-text-6 {
      color: #071952;
    }
  }

  /* Add styles for the TileSelect component here */
  .tile-select {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .edit-icon {
      cursor: pointer;
      transition: transform 0.2s ease;

      svg {
        width: 24px;
        height: 24px;
        fill: #3498db;
        transition: fill 0.2s ease;
      }

      &:hover {
        transform: scale(1.1);
        svg {
          fill: #2980b9;
        }
      }
    }

    &.editing {
      .edit-icon {
        svg {
          fill: #DF1463;
        }
      }
    }

    button {
      margin-left: 16px;
      padding: 8px 16px;
      background-color: #3498db;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #2980b9;
      }
    }
  }
}
