.banner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
}

.left-section {
  display: flex;
  align-items: center;
}

.logo-banner {
  height: 40px;
  margin-right: 10px;
  max-width: 30%;
}

.side-panel-btn {
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.search-bar {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.266);
}

.search-bar input[type="text"] {
  border: none;
  outline: none;
  padding: 5px;
}

.search-icon {
  margin-left: 5px;
}

.close-icon {
  cursor: pointer;
  margin-left: 5px;
}

.right-section {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.notification-icon,
.profile-icon,
.settings-icon {
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.settings-icon {
  position: relative;
}

button.notification-icon {
  margin: 10px;
  padding: 0 15px;
} 

.settings-users {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 27px;
  border: 1px solid var(--Blue-50, #e3f2fd);
  padding: 3px 10px 0px 10px;
}



.side-panel-btn,
.notification-icon,
.profile-icon,
.settings-icon {
  color: #333;
  font-size: 16px;
}

@media screen and (min-width: 390px) and (max-width: 414px) {
  .search-bar.show-search-bar {
    display: flex;
  }
  .search-bar input[type="text"] {
    display: none;
  }

  .search-bar.show-search-bar input[type="text"] {
    display: block;
  }

  .search-bar {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 5px;
    border: none;
    z-index: 999;
  }

  .search-bar.show-search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.266);
    width: 357px;
    margin: 10px;
    top: 1px;
  }

  .logo-banner {
    height: 40px;
    margin-right: 10px;
    max-width: 165%;
  }

  .right-section {
    display: flex;
    align-items: center;
    margin-left: 5rem;
    padding-right: 40px;
  }

  .search-icon {
    display: block;
    position: relative;
    top: -43px;
    left: 195px;
  }

  .show-search-bar .search-icon {
    display: none;
  }

  .show-search-bar .close-icon {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .dashboard.panel-open {
    max-width: 60%;
    transition: max-width 0.2s ease-in-out;
  }

  .logo-banner {
    height: 40px;
    margin-right: 10px;
    max-width: 45%;
  }

  .right-section {
    display: flex;
    align-items: center;
    margin-left: 23rem;
    padding-right: 15px;
  }

  .search-bar {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.266);
    /* Add border with opacity */
  }

  .search-bar input[type="text"] {
    border: none;
    outline: none;
    padding: 5px;
  }

  .search-icon {
    margin-left: 5px;
    pointer-events: none; /* Disable click events */
  }
}
