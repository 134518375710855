.interview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  font-family: Satoshi;
  font-size: 15px;
  font-weight: 500;
  line-height: 0.5;
}
.candidate {
  font-weight: bold;
  margin-right: 10px;
}

button.delete-button {
  position: relative;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

i.delete-icon {
  margin: 10px;
}