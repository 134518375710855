/* Add your custom styles here */
.RegistrationFormContainer {
    display: flex;
    height: 100vh;
    justify-content: center;
}
  
  .RegistrationForm {
    max-width: 400px;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .RegistrationForm h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .RegistrationForm form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .RegistrationForm .form-group {
    width: calc(50% - 10px);
    margin-bottom: 10px;
  }
  
  .RegistrationForm label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .RegistrationForm input[type="text"],
  .RegistrationForm input[type="email"],
  .RegistrationForm input[type="tel"],
  .RegistrationForm input[type="date"],
  .RegistrationForm input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .RegistrationForm button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .RegistrationForm button[type="submit"]:hover {
    background-color: #0056b3;
  }
  