@import url('https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.custom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

label#custom-remeber {
    position: absolute;
    top: 17.4rem;
    font-size: 25px;
    right: 160px;
}

input#custom-remember-me {
    display: flex;
    align-items: center;
    margin-left: 36rem;
    margin-top: -18px;
}

.custom-form_background {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.custom-form-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.custom-form-container label {
    margin-bottom: 5px;
}

.custom-form-container input {
    margin-bottom: 10px;
}

h1.title-hr {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: #fff;
    margin-bottom: 45rem;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
    font-size: 60px;
}

.custom-image-placeholder {
    background-color: #ccc;
    width: 50%;
    height: 100vh;
    border-radius: 20px 0px 0px 20px;
    background-size: cover;
    background-position: center;
    transition: background-image 0.5s ease-in-out;
    animation: slideShow 15s infinite;
}

@keyframes slideShow {
    0% {
        background-image: url('https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/0e3b56173550493.64921f77d7d10.png');
    }

    33.33% {
        background-image: url('https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/32332e173550493.64921f77d8a07.png');
    }

    66.66% {
        background-image: url('https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/58cd51173550493.64921f77d7075.png');
    }

    100% {
        background-image: url('https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/0e3b56173550493.64921f77d7d10.png');
    }
}

.custom-main {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #240046;
    overflow: hidden;
}

.custom-logo {
    margin-left: 33%;
    margin-top: -42%;
    position: absolute;
}

img {
    height: auto;
    max-width: 15%;
}

.custom-form {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 20px;
    margin-left: 5px;
    margin-right: 20px;
    margin-top: 15px;
}

p.custom-forgot-pass {
    font-size: 16px;
    font-family: 'Satoshi';
    font-weight: 300;
    line-height: 1.5;
    margin-left: 20px;
}

/* Checkbox to switch from sign up to login */
#chk {
    display: none;
}

/* Login */
.custom-login {
    position: relative;
    width: 100%;
    height: 100%;
}

.custom-login label {
    margin: 25% 0 5%;
    color: #fff;
}

p.custom-error-login {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 32.5rem;
    right: 522px;
    color: #f22;
}

#custom-passwd {
    margin-top: -26px;
}

.custom-label {
    color: #fff;
    font-size: 2rem;
    justify-content: center;
    display: flex;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

.custom-input {
    width: 75%;
    height: 35px;
    background: #e0dede;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 4px;
    margin-left: 95px;
}

/* Register */
.custom-register {
    background: #eee;
    border-radius: 90% / 10%;
    transform: translateY(5%);
    transition: 0.8s ease-in-out;
}

.custom-register label {
    color: #573b8a;
    transform: scale(0.6);
    font-size: 2rem;
}

#chk:checked~.custom-register {
    transform: translateY(-60%);
}

#chk:checked~.custom-register label {
    transform: scale(1);
    margin: 13% 0 5%;
}

#chk:checked~.custom-login label {
    transform: scale(0.6);
    margin: 5% 0 5%;
}

/* Button */
.custom-form button {
    width: 25%;
    height: 50px;
    margin: 12px auto 10%;
    color: #fff;
    background: #573b8a;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s ease-in;
}

.custom-form button:hover {
    background-color: #6d44b8;
}

@media only screen and (max-width: 390px) {
    .custom-image-placeholder {
        display: none;
    }

    img.custom-logo {
        display: flex;
        z-index: 999;
        justify-content: center;
        left: 1rem;
        top: 17rem;
        width: 30%;
        height: 5%;
        max-width: 30%;
    }

    .custom-form {
        display: flex;
        flex-direction: column;
        gap: 35px;
        padding: 20px;
        margin-left: 5px;
        margin-right: 20px;
        margin-top: 78px;
    }

    #chk:checked~.custom-register label {
        transform: scale(1);
        margin: 0 0 5%;
    }

    .custom-register label {
        font-size: 35px;
        font-family: 'Satoshi';
        font-weight: 700;
        line-height: 1.5;
    }

    #chk:checked~.custom-register {
        transform: translateY(-73%);
    }

    label#custom-remeber {
        position: absolute;
        top: 19rem;
        font-size: 15px;
        right: 4rem;
    }

    input#custom-remember-me {
        display: flex;
        align-items: center;
        margin-left: 11rem;
        margin-top: -32.5px;
    }

    .custom-input {
        width: 75%;
        height: 35px;
        background: #e0dede;
        padding: 10px;
        border: none;
        outline: none;
        border-radius: 4px;
        margin-left: 35px;
        position: relative;
    }
}

@media screen and (min-width: 393px) and (max-width: 412px) {
    label#custom-remeber {
        position: absolute;
        top: 16.4rem;
        font-size: 25px;
        right: 92px;
    }

    input#custom-remember-me {
        display: flex;
        align-items: center;
        margin-left: 36rem;
        margin-top: -18px;
        position: relative;
        top: 1px;
        right: 15rem;
    }

    #chk:checked~.custom-register label {
        transform: scale(1);
        margin: 20% 0 5%;
    }

    .custom-register {
        background: #eee;
        border-radius: 90%/10%;
        transform: translateY(5%);
        transition: 0.8s ease-in-out;
    }
}

@media screen and (min-width: 414px) and (max-width: 767px) {
    label#custom-remeber {
        position: absolute;
        top: 16.4rem;
        font-size: 25px;
        right: 92px;
    }

    input#custom-remember-me {
        display: flex;
        align-items: center;
        margin-left: 36rem;
        margin-top: -18px;
        position: relative;
        top: 1px;
        right: 15rem;
    }

    #chk:checked~.custom-register label {
        transform: scale(1);
        margin: 20% 0 5%;
    }

    .custom-register {
        background: #eee;
        border-radius: 90%/10%;
        transform: translateY(10%);
        transition: 0.8s ease-in-out;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .custom-image-placeholder {
        display: none;
    }

    .custom-form {
        display: flex;
        flex-direction: column;
        gap: 35px;
        padding: 20px;
        margin-left: 5px;
        margin-right: 20px;
        margin-top: -8px;
    }

    img.custom-logo {
        display: flex;
        z-index: 999;
        justify-content: center;
        left: 3rem;
        top: 26rem;
        width: 20%;
        height: 5%;
        max-width: 20%;
    }

    #chk:checked~.custom-register label {
        transform: scale(1);
        margin: 0 0 5%;
    }

    .custom-register label {
        font-size: 35px;
        font-family: 'Satoshi';
        font-weight: 700;
        line-height: 1.5;
    }

    #chk:checked~.custom-register {
        transform: translateY(-73%);
    }

    label#custom-remeber {
        position: absolute;
        top: 15rem;
        font-size: 25px;
        right: 7rem;
    }

    input#custom-remember-me {
        display: flex;
        align-items: center;
        margin-left: 27rem;
        margin-top: -23px;
    }

    .custom-register {
        background: #eee;
        border-radius: 90%/10%;
        transform: translateY(15%);
        transition: 0.8s ease-in-out;
    }
}

@media (min-width: 1326px) and (max-width: 1399px) {
    .custom-image-placeholder {
        width: 100%;
    }

    label#custom-remeber {
        position: absolute;
        top: 18.4rem;
        font-size: 25px;
        right: 90px;
    }

    input#custom-remember-me {
        display: flex;
        align-items: center;
        margin-left: 36rem;
        margin-top: -18px;
        position: relative;
        top: 26px;
        right: 14rem;
    }

    .custom-register {
        background: #eee;
        border-radius: 90%/10%;
        transform: translateY(-10%);
        transition: 0.8s ease-in-out;
    }

    .custom-login label {
        margin: 5% 0 5%;
        color: #fff;
    }

    .custom-logo {
        margin-left: 33%;
        margin-top: -35%;
        position: absolute;
        top: 28rem;
    }
}
