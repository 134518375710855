.error404 {
    display: block;
    max-width: 40%;
    margin-top: 100px;

}

h2.subtitle.is-3 {
    display: flex;
    position: absolute;
    top: 7rem;
    right: 44rem;
}

h1.title.is-1 {
    display: block;
    position: absolute;
    top: 89px;
    left: 53rem;
}

a.home {
    position: absolute;
    font-size: 46px;
    font-family: fantasy;
    font-weight: 700;
    top: 38rem;
    text-decoration: none;
}