@import url("https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap");

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  padding: 8px 16px;
  font-family: "Satoshi", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 0.5;
  color: #fff;
  background-color: #3e3298;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  display: flex; /* Use flexbox to align the icon and text */
  align-items: center; /* Vertically center the content */
}

.svg-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px; /* Add some spacing between the icon and the text */
  color: #fff;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  padding: 8px 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 150px;

  li {
    padding: 8px 16px;
    font-family: "Satoshi", sans-serif;
    color: #3e3298;
    font-size: 14px;
    line-height: 0.5;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
      color: #3e3298;
    }
  }
}
