@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,100,500);

.chart-container-1 {
  position: relative;
  max-height: 26rem;
  top: 52rem;
  left: -104.8rem;
  border: solid 1px rgba(100, 37, 197, 0.3411764706);
  border-radius: 15px;
  width: 65rem;
  background: #451397;
  box-shadow: rgba(14, 30, 37, 0.12) -10px 14px 14px 0px,
    rgba(14, 30, 37, 0.32) -7px 7px 8px 0px;
  overflow: hidden;
}

.chart-container-1:before,
.chart-container-1:after {
  content: "";
  position: absolute;
}

.chart-container-1:before {
  top: -143px;
  left: -66px;
  width: 211px;
  height: 211px;
  background: linear-gradient(141deg, #90caf9 0%, rgba(144, 202, 249, 0) 100%);
  border-radius: 50%;
}

.chart-container-1:after {
  bottom: -131px;
  right: -72px;
  width: 211px;
  height: 211px;
  border-radius: 50%;
  background: linear-gradient(297deg, #90caf9 0%, rgba(144, 202, 249, 0) 100%);
}

.chart-container-1 .shape1-chart {
  position: absolute;
  top: -69px;
  left: -134px;
  width: 211px;
  height: 211px;
  background: linear-gradient(210deg, #90caf9 0%, rgba(144, 202, 249, 0) 100%);
  border-radius: 50%;
}

.chart-container-1 .shape2-chart {
  position: absolute;
  top: 18rem;
  left: 60rem;
  width: 211px;
  height: 211px;
  border-radius: 50%;
  background: linear-gradient(11deg, #90caf9 0%, rgba(144, 202, 249, 0) 100%);
}

.chart-container {
  margin-left: 75px;
  margin-bottom: -70px;
  margin-right: 27rem;
}

$color-paid: #ecff62;
$color-sent: #0092ff;

#q-graph {
  display: block;
  position: relative;
  width: 48rem;
  height: 300px;
  margin: 1.1em 0 0;
  padding: 0;
  background: transparent;
  font-size: 11px;
}

#q-graph caption {
  caption-side: top;
  width: 600px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  top: -40px;
  position: relative;
  z-index: 10;
  font-weight: bold;
}

#q-graph tr,
#q-graph th,
#q-graph td {
  position: absolute;
  bottom: 0;
  width: 107px;
  z-index: 2;
  margin: 0;
  padding: 0;
  text-align: center;
}

#q-graph td {
  transition: all 0.3s ease;

  &:hover {
    background-color: desaturate(#85144b, 100);
    opacity: 0.9;
    color: white;
  }
}

#q-graph thead tr {
  left: 100%;
  top: 50%;
  bottom: auto;
  margin: -2.5em 0 0 5em;
}

#q-graph thead th {
  width: 7.5em;
  height: auto;
  padding: 0.5em 1em;
}

#q-graph thead th.sent {
  top: 0;
  left: 0;
  line-height: 2;
}

#q-graph thead th.paid {
  top: 2.75em;
  line-height: 2;
  left: 0;
}

#q-graph tbody tr {
  height: 296px;
  padding-top: 2px;
  border-right: 1px dotted #c4c4c4;
  color: #aaa;
}

#q-graph #q1 {
  left: 0;
}

#q-graph #q2 {
  left: 110px;
}

#q-graph #q3 {
  left: 220px;
}

#q-graph #q4 {
  left: 330px;
}

#q-graph #q5 {
  left: 440px;
}

#q-graph #q6 {
  left: 550px;
}

#q-graph #q7 {
  left: 660px;
  border-right: none;
}

#q-graph tbody th {
  bottom: -1.75em;
  vertical-align: top;
  font-weight: normal;
  color: #ffffff;
}

#q-graph .bar {
  width: 35px;
  border: 1px solid;
  border-bottom: none;
  color: #000;
}

#q-graph .bar p {
  margin: 5px 0 0;
  padding: 0;
  opacity: 0.4;
}

#q-graph .sent {
  left: 13px;
  background-color: $color-sent;
  border-color: transparent;
}

#q-graph .paid {
  left: 55px;
  background-color: $color-paid;
  border-color: transparent;
}

#ticks {
  position: relative;
  top: -300px;
  left: 2px;
  width: 500px;
  height: 300px;
  z-index: 1;
  margin-bottom: -300px;
  font-size: 10px;
  font-family: "Satoshi";
  color: #fff;
}

#ticks .tick {
  position: relative;
  border-bottom: 1px dotted #c4c4c4;
  width: 765px;
}

#ticks .tick p {
  position: absolute;
  left: -5em;
  top: -0.8em;
  margin: 0 0 0 0.5em;
}

.month-button {
  border: solid 1px rgba(100, 37, 197, 0.3411764706);
  border-radius: 15px;
  width: 5rem;
  background: #8980ca;
  height: 2rem;
  color: #fff;
  font-family: "Satoshi";
  font-size: 15px;
  font-weight: 400;
  line-height: 0.5;
}

.week-button {
  border: solid 1px rgba(100, 37, 197, 0.3411764706);
  border-radius: 15px;
  width: 5rem;
  background: #6cdc60;
  height: 2rem;
  color: #fff;
  font-family: "Satoshi";
  font-size: 15px;
  font-weight: 400;
  line-height: 0.5;
}

.month {
  position: relative;
  top: -21rem;
  left: 50rem;
}

.week {
  position: relative;
  top: -23rem;
  left: 55.5rem;
}
