.images-upload-container {
    position: relative;
    cursor: pointer;
  }
  
  .image-placeholder-applicants {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    max-width: 100%;
  }
  
  .images-upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 50%;
  }
  
  .images-upload-overlay:hover {
    opacity: 1;
  }
  
  .images-upload-icon {
    font-size: 36px;
    margin-bottom: 8px;
  }
  
  .images-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: white;
  }
  
  .images-modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  
  .image-placeholder-container {
    display: flex;
    position: absolute;
    left: 10rem;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
    margin: 0;
    align-items: center;
    width: 160px;
    top: 6rem;
  }
  
  img.image-placeholder-applicants {
    border-radius: 50%;
    max-width: 100%;
  }
  