@import url("https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap");

.SignaturePad {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
}

.SignatureCanvas {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  background-color: #f1f1f1; /* Add the desired background color here */
  /* You can adjust the width and height if needed */
  width: 500px;
  height: 200px;
}

.ButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.ButtonsContainer button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.SignatureButtons-1 {
  color: #fff;
  margin: 15px;
  display: flex;
  background: rgb(22, 46, 135);
  font-size: 1rem;
  font-weight: bold;
  font-family: "Satoshi";
  line-height: 2.5;
  border: none;
  border-radius: 4px;
  position: absolute;
  bottom: -12px;
  left: 72px;
  cursor: pointer;
  transition: 0.2s ease-in;
  justify-content: center;
}

.SignatureButtons-2 {
  color: #fff;
  margin: 15px;
  display: flex;
  background: rgba(82, 112, 219, 0.831372549);
  font-size: 1rem;
  font-weight: bold;
  font-family: "Satoshi";
  line-height: 2.5;
  border: none;
  border-radius: 4px;
  position: absolute;
  top: 11.9rem;
  cursor: pointer;
  transition: 0.2s ease-in;
  justify-content: center;
}

.SignatureButtons-submit{
  display: none;
}

label.SignatureButtons-3 {
  width: 305px;
  height: 22px;
  color: #fff;
  padding: 10px;
  display: none;
  margin: 10px;
  background: rgba(82, 112, 219, 0.831372549);
  font-size: 1rem;
  font-weight: bold;
  font-family: "Satoshi";
  line-height: 2.5;
  position: absolute;
  top: 195px;
  left: 9rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-in;
  align-items: center;
}

.UploadedImage {
  max-width: 200px; /* Set the maximum width for the uploaded image */
  max-height: 100px; /* Set the maximum height for the uploaded image */
}

.uploaded-sig-container {
  display: flex;
  position: absolute;
  top: 1rem;
  right: 14rem;
  align-items: center;
  flex-direction: column;
  border: 1px solid #c5252575;
  padding: 10px;
  border-radius: 10px;
}

.SignatureButtons-up {
  color: #fff;
  margin: 15px;
  display: flex;
  background: rgba(82, 112, 219, 0.831372549);
  font-size: 1rem;
  font-weight: bold;
  font-family: "Satoshi";
  line-height: 2.5;
  border: none;
  border-radius: 4px;
  position: absolute;
  bottom: -12px;
  left: 73px;
  cursor: pointer;
  transition: 0.2s ease-in;
  justify-content: center;
}

.SignatureButtons-cancel {
  color: #fff;
  margin: 15px;
  display: flex;
  background: rgba(82, 112, 219, 0.831372549);
  font-size: 1rem;
  font-weight: bold;
  font-family: "Satoshi";
  line-height: 2.5;
  border: none;
  border-radius: 4px;
  position: absolute;
  top: 11.9rem;
  cursor: pointer;
  transition: 0.2s ease-in;
  justify-content: center;
}

.images-esignature {
  margin-top: 20px;
  border: 1px solid black;
  border-radius: 15px;
}