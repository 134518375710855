@import url("https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap");

.notes-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;

  .modal-content-notes {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 970px;
    height: 70%;
    font-family: "Satoshi", sans-serif;

    .notes-label {
      margin-top: 0;
      font-size: 24px;
      text-align: center;
      background-color: #007bff;
      color: white;
      padding: 15px;
      border-radius: 8px 8px 0 0;
      position: relative;
      top: -22px;
    }

    input {
      width: 104%;
      padding: 7px 5px 24px 5px;
      margin: 18px;
      border: 1px solid #ccc;
      border-radius: 4px;
      resize: vertical;
      font-family: "Satoshi", sans-serif;
    }

    button {
      padding: 10px 20px;
      margin: 10px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #0056b3;
      }
    }

    .notes-list {
      margin-top: 20px;
      height: 300px;
      max-height: 300px;
      overflow-y: auto;

      .note {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 10px;
        margin: 13px;
        font-weight: bold;
        font-size: 15px;
        overflow-wrap: break-word;

        .user-details {
          display: flex;
          align-items: center;

          .user-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #ccc;
            margin-right: 10px;
          }

          .user-info {
            display: flex;
            flex-direction: column;

            .user-name {
              font-weight: bold;
              font-size: 16px;
            }

          }
        }

        .note-text {
          margin-top: 10px;
          font-weight: bold;
          font-size: 14px;
        }
        

        .note-status {
          display: inline-flex;
          font-weight: bold;
          font-size: 15px;
          font-family: "Satoshi";
          align-items: center;
        }


        /* Define styles for different status values */
        &.Failed .note-status {
          color: #ff0000;
        }

        &.Endorsed .note-status {
          color: #00cc00;
        }

        &.ForExamRetake .note-status {
          color: #ff9900;
        }

        &.EndorsedForInterview .note-status {
          color: #6600cc;
        }

        &.ForReserved .note-status {
          color: #ff6600;
        }

        &.Passed .note-status {
          color: #00cc99;
        }
      }
    }

    .timestamp {
      color: #666;
      font-weight: 500;
      font-size: 12px;
    }

    .close-icon-notes {
      display: flex;
      justify-content: flex-end;
      margin-top: -15px;
      margin-right: -15px;
      cursor: pointer;
      position: relative;
      z-index: 999;
    }

    .note-status-label {
      display: inline-flex;
      font-size: 10px;
      font-family: Satoshi;
      font-weight: 500;
      color: #451397;
      flex-direction: row-reverse;
      align-items: center;
    }

    .notes-data-area {
      display: inline-block;
      justify-content: center;
    }
    .notes-radio-buttons {
      padding:0 5px 0;
    }

    /* Hover styles for status labels */
    .note-status-label:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
