@import url("https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap");
.form-control:focus {
  box-shadow: none;
  border-color: #ba68c8;
}

.back:hover {
  color: #682773;
  cursor: pointer;
}

.position-apply {
  display: flex;
  position: absolute;
  top: 34px;
  left: 292px;
}

.labels-position {
  font-family: "Satoshi";
  font-size: 12px;
  font-weight: 700;
  display: flex;
  padding: 10px;
}

input.form-control-position {
  width: 186px;
}

.col-md-8 {
  display: flex;
  align-items: center;
}

.name-applicant-label {
  display: flex;
  position: absolute;
  top: 60px;
  background: #3e3298;
  width: 60rem;
  justify-content: center;
  left: 17rem;
  font-size: 12px;
  font-family: Satoshi;
  color: #fff;
}

.labels {
  font-size: 11px;
}

.add-experience:hover {
  background: #ba68c8;
  color: #fff;
  cursor: pointer;
  border: solid 1px #ba68c8;
}

.applicants-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  background: #e3efff;
  border: 1px solid #b8b8b8;
  margin: 15px;
  justify-content: center;
  border-radius: 15px;
  align-items: center;
  width: 118rem;
  height: 56rem;
}

.dropdownlist-applicant {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 11rem;
  left: 20rem;
}

.marital-status {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 11rem;
  left: 517px;
}

.parents-container {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 11rem;
  left: 715px;
}

.applicant-form-label {
  display: flex;
  position: absolute;
  top: 0rem;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.row.mt-2 {
  display: flex;
  padding: 10px;
  position: absolute;
  left: 23rem;
}

.col-md-6,
.col-md-12 {
  padding: 10px;
}

.labels-applicants {
  font-size: 11px;
}

.contact-info {
  display: flex;
  position: absolute;
  left: 20rem;
  top: 8rem;
}

.address-applicants {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  left: 20rem;
  top: 16rem;
}

.address-applicants-2 {
  display: flex;
  position: absolute;
  top: 50px;
}

.school {
  display: flex;
  position: absolute;
  align-items: center;
  top: 24rem;
  left: 20rem;
}

.name-applicant {
  display: flex;
  position: absolute;
  left: 20rem;
  top: 5rem;
}

.applicant-address-label {
  display: flex;
  position: absolute;
  top: 233px;
  background: #3e3298;
  width: 60rem;
  justify-content: center;
  left: 17rem;
  font-size: 12px;
  font-family: Satoshi;
  color: #fff;
}

.applicant-school-label {
  display: flex;
  position: absolute;
  top: 23rem;
  background: #3e3298;
  width: 60rem;
  justify-content: center;
  left: 17rem;
  font-size: 12px;
  font-family: Satoshi;
  color: #fff;
}

.applicant-work {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 23rem;
}

.application-submit {
  display: flex;
  margin: 12px auto 10%;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-in;
  position: absolute;
  top: 50rem;
}

h4.applicant-work-label {
  display: flex;
  position: absolute;
  top: 28rem;
  background: #3e3298;
  width: 60rem;
  justify-content: center;
  left: 17rem;
  font-size: 12px;
  font-family: Satoshi;
  color: #fff;
}

.applicant-work {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  left: -228px;
  justify-content: center;
  top: 11rem;
}

.applicant-work-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  left: -227px;
  justify-content: center;
  top: 10rem;
}

.applicant-work .col-md-6 {
  flex: 1;
  margin-right: 10px;
}

.applicant-work .btn-danger {
  margin-top: 10px;
}

.add-work-experience-button {
  margin-top: 15px;
  position: absolute;
  text-align: center;
  display: flex;
  top: 29rem;
  align-items: center;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ba68c8;
}

.labels {
  font-size: 11px;
}

.add-experience:hover {
  background: #ba68c8;
  color: #fff;
  cursor: pointer;
  border: solid 1px #ba68c8;
}

.images-applicant {
  display: flex;
  position: absolute;
  right: 14rem;
  top: 7rem;
}

.image-note {
  display: flex;
  position: absolute;
  top: 20rem;
  right: 12rem;
  justify-content: center;
  width: 15rem;
  font-size: 13px;
  font-family: "Satoshi";
  line-height: 1.5;
  font-weight: 500;
}

.images-esignature {
  display: flex;
  position: absolute;
  right: 5rem;
  top: 25rem;
}

.images-esignature {
  margin-top: 20px;
  border: 1px solid black;
}

.emergency-contact-label {
  display: flex;
  position: absolute;
  top: 38rem;
  background: #3e3298;
  width: 60rem;
  justify-content: center;
  left: 17rem;
  font-size: 12px;
  font-family: Satoshi;
  color: #fff;
}

.emergency-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  left: -228px;
  justify-content: center;
  top: 11rem;
}

.government-id {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  left: -327px;
  justify-content: center;
  top: 12rem;
}

.government-id-label {
  display: flex;
  position: absolute;
  top: 43rem;
  background: #3e3298;
  width: 60rem;
  justify-content: center;
  left: 17rem;
  font-size: 12px;
  font-family: Satoshi;
  color: #fff;
}

/* Define styles for the custom alert */
.custom-alert {
  padding: 10px;
  border: 2px solid #ccc;
  background-color: #f7f7f7;
  color: #333;
  margin-top: 10px;
  display: none; /* Hide the alert by default */
}

/* Style for success message */
.custom-alert.success {
  border-color: #66bb6a;
  background-color: #e8f5e9;
  color: #2e7d32;
}

/* Style for error message */
.custom-alert.error {
  border-color: #ef5350;
  background-color: #ffebee;
  color: #b71c1c;
}

/* style.scss */

/* Custom modal styles */
.custom-modal {
  width: 300px;
  text-align: center;
  animation: zoomIn 0.3s;
}

/* Custom modal styles for success modal */
.success-modal {
  background-color: #dff0d8; /* Change to your desired background color */
  border-color: #d6e9c6; /* Change to your desired border color */
  color: #3c763d; /* Change to your desired text color */
}

/* Custom modal styles for warning modal */
.warning-modal {
  background-color: #fcf8e3; /* Change to your desired background color */
  border-color: #faebcc; /* Change to your desired border color */
  color: #8a6d3b; /* Change to your desired text color */
}

/* Animation for modal */
@keyframes zoomIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
  display: none;
}

.react-datetime-picker__wrapper {
  background: #fff;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
  width: 177px;
}

.react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 0;
}

.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  display: flex;
  justify-content: center;
  font-family: "Satoshi";
  font-size: 13px;
  font-weight: 500;
  align-items: center;
}

.react-calendar {
  border-radius: 15px;
  margin: 2px;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
  border-radius: 100%;
  height: 45px;
}

.react-calendar__tile--now {
  background: #ffff76;
  color: #000;
  border-radius: 100%;
  height: 45px;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;

  text-align: center;
  line-height: 16px;
  border-radius: 100%;
  height: 45px;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-family: "Satoshi";
  font-size: 17px;
  font-weight: 500;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  background: bisque;
  border-radius: 15px 15px 0 0px;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month {
  border-radius: 15%;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
}
