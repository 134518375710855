@import url('https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it is displayed above other elements */
    opacity: 0; /* Start with opacity 0, will be transitioned to 1 when modal opens */
    pointer-events: none; /* Hide the modal from capturing interactions initially */
    transition: opacity 0.3s ease; /* Opacity transition for smooth display */
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 95rem;
    height: 40rem;
}
  
  .modal-overlay.open {
    opacity: 1; /* Show the modal with opacity 1 when it's open */
    pointer-events: auto; /* Allow the modal to capture interactions */
  }
  
  .close-icon {
    cursor: pointer;
    position: relative;
    top: -46px;
    left: 95rem;
    color: #fff;
}
  
.label-pooling {
  font-family: 'satoshi';
  font-size: 25px;
  font-weight: 800;
  line-height: 0.5;
}