@import url('https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.application-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 20px;
    border: solid 1px #383f73;
    background: #162e9e;
    color: #fff;
    position: relative;
    overflow: hidden;
    /* Ensure pseudo-elements stay within the box */
    box-shadow: rgba(14, 30, 37, 0.12) 0px 7px 14px 0px,
        rgba(14, 30, 37, 0.32) 0px 7px 8px 0px;
}


.application-box::before,
.application-box::after,
.application-box .shape1-app,
.application-box .shape2-app {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    pointer-events: none;
}

.application-box::before {
    top: 80px;
    left: -10px;
    background-color: #fff;
    opacity: 0.2;
}

.application-box::after {
    bottom: 87px;
    right: -24px;
    background-color: #a292dc;
    opacity: 0.1;
}

.application-box .shape1-app {
    top: 60px;
    left: -32px;
    background-color: #d3cfe4;
    opacity: 0.1;
}

.application-box .shape2-app {
    bottom: 60px;
    right: -40px;
    background-color: #a292dc;
    opacity: 0.5;
}


.small-box-1,
.small-box-2,
.small-box-3,
.small-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 125px;
    transition: transform 0.3s ease-in-out;
}

.small-box-1,
.small-box-2,
.small-box-3,
.small-box,
.label,
.number-app {
    align-items: center;
    font-family: Satoshi;
    font-size: 16px;
    font-weight: 600;
    line-height: 0.5;
    margin: 10px;
}

.small-box-2 {
    position: relative;
}

.small-box-2::before,
.small-box-2::after {
    content: '';
    position: absolute;
    top: 12px;
    bottom: 0;
    left: 140px;
    width: 1px;
    height: 60px;
    background-color: #ececec;
}

.small-box-2::after {
    left: -10px;
}

.small-box-1:hover,
.small-box-2:hover,
.small-box-3:hover {
    transform: translateY(-5px);
    cursor: pointer;
}

.tile-appbox {
    display: inline-block;
    justify-content: center;
    padding: 10px;
    position: relative;
    left: -14.9rem;
    bottom: -27rem;
    max-width: 47%;
}

label {
    display: flex;
    font-size: 25px;
    font-family: Satoshi;
    font-weight: 700;
    color: #451397;
    padding-top: 13px;
    padding-left: 8px;
}

.label-tile-appbox-2-container {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: -2rem;
}


.small-box {
    position: relative;
}

.small-box::before,
.small-box::after {
    content: "";
    position: absolute;
    top: -8px;
    bottom: 0;
    left: 150px;
    width: 1px;
    height: 60px;
    background-color: #ececec;
}

.small-box::after {
    left: -26px;
}

.small-box:hover,
.small-box:hover,
.small-box:hover {
    transform: translateY(-5px);
    cursor: pointer;
}

p.label-app-2 {
    position: relative;
    top: 16px;
}

.label-tile-appbox-2 {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: -2rem;
}